import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Makroevoluce: Vývoj vyšších taxonomických jednotek" />
    <h1>Makroevoluce: Vývoj vyšších taxonomických jednotek</h1>
    <p>Makroevoluce je evoluce vy&scaron;&scaron;&iacute;ch taxonomick&yacute;ch jednotek nad &uacute;rovn&iacute; druhu (jde tedy o evoluci rodů, čeled&iacute; atd.). Narozd&iacute;l od mikroevoluce, kter&aacute; sleduje evoluci niž&scaron;&iacute;ch taxonomick&yacute;ch jednotek pod &uacute;rovn&iacute; druhu, je rozeznateln&aacute; ve fosiln&iacute;m z&aacute;znamu.</p>
<hr />
<h2>Druhov&yacute; v&yacute;běr</h2>
<p>Druhov&yacute; v&yacute;běr je analogi&iacute; př&iacute;rodn&iacute;ho v&yacute;běru, ale na vy&scaron;&scaron;&iacute; &uacute;rovni. M&iacute;sto jedinců jsou selektov&aacute;ny cel&eacute; druhy. Např&iacute;klad při n&aacute;hl&eacute; změně podm&iacute;nek může doch&aacute;zet k rychl&eacute; speciaci, nebo extinkci (vym&iacute;r&aacute;n&iacute;) druhů.</p>
<p>Nejčastěj&scaron;&iacute;mi př&iacute;činami jsou u rychl&eacute; speciace n&aacute;hoda (n&aacute;hodn&eacute; uvolněn&iacute; nik), kolonizace nov&eacute;ho &uacute;zem&iacute; či evolučn&iacute; novinka (semennost u rostlin). Konkr&eacute;tně u rychl&eacute; speciace savců na poč&aacute;tku třetihor jsou př&iacute;činami dvě evolučn&iacute; novinky (termoregulace a živorodost) i n&aacute;hodn&aacute; změna podm&iacute;nek, kdy do&scaron;lo k vymřen&iacute; dinosaurů a uvolněn&iacute; ekologick&yacute;ch nik.</p>
<hr />
<h2 id="extinkce">Vym&iacute;r&aacute;n&iacute; (extinkce)</h2>
<p>Vym&iacute;r&aacute;n&iacute;, neboli extinkce, je z&aacute;nik existence dan&eacute;ho konkr&eacute;tn&iacute;ho taxonu, nejběžněji druhu. Jde o konečn&yacute; osud každ&eacute;ho druhu, kdy je nahrazen nov&yacute;m a l&eacute;pe adaptovan&yacute;m druhem (typick&yacute; př&iacute;klad dinosauři -&gt; savci a&nbsp;pt&aacute;ci).</p>
<p>Extinkce jsou nez&aacute;visl&eacute; na evolučn&iacute;m st&aacute;ř&iacute; druhu, ale na taxonomick&eacute;m postaven&iacute; a ekologick&eacute; nice. Např&iacute;klad vlivem trofick&eacute; &uacute;rovně a&nbsp;velikost&iacute; populace vym&iacute;raj&iacute; mal&iacute; herbivoři m&eacute;ně často než velc&iacute; pred&aacute;toři. Mal&eacute; populace a populace v&aacute;zan&eacute; na velmi specifick&eacute; podm&iacute;nky jsou k vym&iacute;r&aacute;n&iacute; obecně n&aacute;chylněj&scaron;&iacute;.</p>
<p>Podle <strong>hypot&eacute;zy červen&eacute; kr&aacute;lovny</strong> se druh mus&iacute; neust&aacute;le vyv&iacute;jet nejen kvůli změn&aacute;m prostřed&iacute;, ale tak&eacute; protože se ostatn&iacute; druhy takt&eacute;ž vyv&iacute;j&iacute;. Jinak takov&yacute; druh zvy&scaron;uje pravděpodobnost sv&eacute; vlastn&iacute; extinkce.</p>
<h3>Hromadn&aacute; vym&iacute;r&aacute;n&iacute;</h3>
<p>Hromadn&eacute; vym&iacute;r&aacute;n&iacute; je ud&aacute;lost, při kter&eacute; doch&aacute;z&iacute; k extinkci vysok&eacute;ho počtu druhů, č&iacute;mž v&yacute;znamně klesne biodiverzita. V historii Země do&scaron;lo k hlavn&iacute;m hromadn&yacute;m vym&iacute;r&aacute;n&iacute;m, při kter&yacute;ch vymřelo v&iacute;ce než 75&nbsp;% druhů, v&nbsp;těchto pěti obdob&iacute;ch:</p>
<ul>
<li><strong>ordovik-silur</strong></li>
<li><strong>svrchn&iacute; devon</strong></li>
<li><strong>perm-trias</strong></li>
<li><strong>trias-jura</strong></li>
<li><strong>kř&iacute;da-paleog&eacute;n</strong></li>
</ul>
<h4>Ordovicko-silursk&eacute; vym&iacute;r&aacute;n&iacute;</h4>
<p>Na pomez&iacute; ordoviku a siluru, zhruba před 435 až 443 miliony let, do&scaron;lo nejsp&iacute;&scaron;e poklesem CO2 k n&aacute;hl&eacute;mu glob&aacute;ln&iacute;mu ochlazen&iacute;. N&aacute;sledně do&scaron;lo opět k glob&aacute;ln&iacute;mu oteplen&iacute;. Vymřelo tehdy 25&nbsp;% mořsk&yacute;ch čeled&iacute; (suchozemsk&eacute; je&scaron;tě prakticky nebyly), což bylo zhruba 85&nbsp;% druhů.</p>
<h4>Vym&iacute;r&aacute;n&iacute; ve svrchn&iacute;m devonu</h4>
<p>Ve svrchn&iacute;m devonu, zhruba před 365 až 375 miliony let, do&scaron;lo nejsp&iacute;&scaron;e z klimatick&yacute;ch důvodů (př&iacute;činy nejasn&eacute;) k dal&scaron;&iacute; masov&eacute; extinkci. Kvůli poklesu obsahu kysl&iacute;ku ve světov&eacute;m oce&aacute;nu a poklesu jeho hladiny vymřelo asi 19&nbsp;% čeled&iacute;, což činilo cirka 75&nbsp;% druhů. Vym&iacute;r&aacute;n&iacute; v&scaron;ak mohlo trvat několik mili&oacute;nů let a bylo sp&iacute;&scaron;e postupn&eacute;. Nověj&scaron;&iacute; poznatky naznačuj&iacute;, že mohlo b&yacute;t <a href="https://phys.org/news/2019-06-troublesome-trees-forests-contributed-ancient.html" target="_blank" rel="noreferrer noopener">způsobeno rapidn&iacute;m zalesňov&aacute;n&iacute;m kontinetů</a>.</p>
<h4>Permsk&eacute; vym&iacute;r&aacute;n&iacute;</h4>
<p>Na pomez&iacute; permu a triasu, zhruba před 252 miliony let, do&scaron;lo k <strong>největ&scaron;&iacute;mu hromadn&eacute;mu vym&iacute;r&aacute;n&iacute;</strong> v historii Země. Jeho př&iacute;činou byla patrně tektonick&aacute; aktivita při vzniku kontinentu Pangae, d&aacute;le vulkanick&aacute; aktivita na Sibiři a z toho vypl&yacute;vaj&iacute;c&iacute; klimatick&eacute; změny a acidifikace oce&aacute;nů. Celkem vymřelo až 51&nbsp;% mořsk&yacute;ch čeled&iacute; a 95&nbsp;% druhů.</p>
<h4>Vym&iacute;r&aacute;n&iacute; na konci triasu</h4>
<p>Na pomez&iacute; triasu a jury, zhruba před 201 miliony let, doch&aacute;z&iacute; z nezn&aacute;m&yacute;ch př&iacute;čin (spekuluje se o opětovn&eacute; tektonick&eacute; aktivitě) k vymřen&iacute; 23&nbsp;% čeled&iacute;, tedy asi 76&nbsp;% druhů.</p>
<h4>Vym&iacute;r&aacute;n&iacute; na pomez&iacute; kř&iacute;dy a paleog&eacute;nu</h4>
<p>Na pomez&iacute; kř&iacute;dy a paleog&eacute;nu, zhruba před 66 miliony let, do&scaron;lo patrně p&aacute;dem vesm&iacute;rn&eacute;ho tělesa k nejzn&aacute;měj&scaron;&iacute;mu vym&iacute;r&aacute;n&iacute;, a to extinkci dinosaurů. Objevuj&iacute; se v&scaron;ak n&aacute;zory, že dinosauři již pomalu vym&iacute;rali dř&iacute;ve, za což by mohla změna vegetace, nejpravděpodobněji pak n&aacute;stup krytosemenn&yacute;ch rostlin. Vymřelo 17&nbsp;% čeled&iacute; a přibližně 80&nbsp;% druhů.</p>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Makroevoluce</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/evolucni/speciace/"><button className="inv">&larr; Speciace</button></Link>
    <Link to="/biogeografie/disperze/"><button className="inv">Disperze druhů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
